import { EventObject, Typestate } from 'xstate';
import { AppMode } from '../../stores/order.store';
import { Application, Order, SubmitApplicationError } from '../../gql/graphql';

// Context Types
export type NewApplicationMachineContext = {
  applyErrors: SubmitApplicationError[];
  appMode: AppMode | null;
  order: Order | null;
  // the application is from the apply result and different from the application in the order
  // this drives the events changes to approved
  application: Application | null;
};

// State Types
export enum NewApplicationStates {
  BillingAddress = 'BillingAddress',
  Approval = 'Approval',
  Decline = 'Decline',
  ExistingApplication = 'ExistingApplication',
  ApplicationApply = 'ApplicationApply',
  LoanDeclined = 'LoanDeclined',
  LeaseDeclined = 'LeaseDeclined',
  LTOIneligible = 'LTOIneligible',
  Loading = 'BillingAddressLoading',
  GeneralError = 'GeneralError',
  BillingAddressErrors = 'BillingAddressErrors',
  CustomerIdProgram = 'CustomerIdProgram',
  TermsAndConditions = 'TermsAndConditions',
  PrequalificationNotice = 'PrequalificationNotice',
  AuthorizedUserTerms = 'AuthorizedUserTerms',
}

export interface NewApplicationMachineStatesSchema {
  [NewApplicationStates.BillingAddress]: {};
  [NewApplicationStates.Approval]: {};
  [NewApplicationStates.Decline]: {};
  [NewApplicationStates.ExistingApplication]: {};
  [NewApplicationStates.LoanDeclined]: {};
  [NewApplicationStates.LeaseDeclined]: {};
  [NewApplicationStates.LTOIneligible]: {};
  [NewApplicationStates.Loading]: {};
  [NewApplicationStates.GeneralError]: {};
  [NewApplicationStates.BillingAddressErrors]: {};
  [NewApplicationStates.ApplicationApply]: {};
  [NewApplicationStates.CustomerIdProgram]: {};
  [NewApplicationStates.TermsAndConditions]: {};
  [NewApplicationStates.PrequalificationNotice]: {};
  [NewApplicationStates.AuthorizedUserTerms]: {};
}

export interface NewApplicationMachineStates extends Typestate<NewApplicationMachineContext> {
  type: NewApplicationMachineStatesSchema;
}

// Event Types
export enum NewApplicationEvents {
  NEXT = 'NEXT',
  VALIDATION_ERROR = 'ValidationError',
  BACK = 'BACK',
  CUST_LOOKUP = 'CustomerLookup',
  VIEW_CUSTOMER_ID_PROGRAM = 'ViewCustomerIdProgram',
  VIEW_TERMS_AND_CONDITIONS = 'ViewTermsAndConditions',
  VIEW_PREQUALIFICATION_NOTICE = 'ViewPrequalificationNotice',
  VIEW_AUTHORIZED_USER_TERMS = 'ViewAuthorizedUserTerms',
}

type NewApplicationEventsTypeSchema =
  | NewApplicationEvents.NEXT
  | NewApplicationEvents.VALIDATION_ERROR
  | NewApplicationEvents.BACK
  | NewApplicationEvents.CUST_LOOKUP
  | NewApplicationEvents.VIEW_AUTHORIZED_USER_TERMS
  | NewApplicationEvents.VIEW_CUSTOMER_ID_PROGRAM
  | NewApplicationEvents.VIEW_PREQUALIFICATION_NOTICE
  | NewApplicationEvents.VIEW_TERMS_AND_CONDITIONS;

export interface NewApplicationMachineEvents extends EventObject {
  type: NewApplicationEventsTypeSchema;
  data?: any;
}

// Guard Types
export enum NewApplicationGuardsType {
  LEASE_DECLINED = 'LeaseDecline',
  LOAN_DECLINED = 'LoanDecline',
  LTO_INELIGIBLE = 'LTOIneligible',
  APP_APPROVED = 'AppApproved',
  GENERAL_APPLY_ERRORS = 'GeneralApplyErrors',
  SOFT_DECLINE = 'SoftDecline',
}
type NewApplicationGuardsTypeSchema =
  | NewApplicationGuardsType.LEASE_DECLINED
  | NewApplicationGuardsType.LOAN_DECLINED
  | NewApplicationGuardsType.LTO_INELIGIBLE
  | NewApplicationGuardsType.GENERAL_APPLY_ERRORS
  | NewApplicationGuardsType.SOFT_DECLINE
  | NewApplicationGuardsType.APP_APPROVED;

export interface NewApplicationMachineGuards extends EventObject {
  type: NewApplicationGuardsTypeSchema;
}

import { actions, assign, createMachine } from 'xstate';
import { AppMode } from '../stores/order.store';
import {
  ExistingApplicationEvents,
  ExistingApplicationMachineContext,
  ExistingApplicationMachineEvents,
  ExistingApplicationMachineGuards,
  ExistingApplicationMachineStates,
  ExistingApplicationStates,
} from './types/existingApplication.types';
import { EcommMachineEvents } from './types/ecommMachine.types';
import { NavigationSections } from '../constants';
import { UpdateDebitErrors, UpdateBankAccountErrors, ChannelTypes } from '../gql/graphql';

export default createMachine<
  ExistingApplicationMachineContext,
  ExistingApplicationMachineEvents,
  ExistingApplicationMachineStates
>(
  {
    id: 'ExistingApplication',
    initial: ExistingApplicationStates.ApplicationExists,
    context: {
      order: null,
      appMode: null,
      skipApproval: false,
      canGoBackViaHeader: false,
      skipToPage: null,
    },
    states: {
      [ExistingApplicationStates.ApplicationExists]: {
        always: [
          { target: ExistingApplicationStates.VirtualCardApplication, cond: 'VirtualCardDealer' },
          { target: ExistingApplicationStates.LeaseApplication, cond: 'AppModeLease' },
          { target: ExistingApplicationStates.LoanApplication, cond: 'AppModeLoan' },
        ],
      },
      [ExistingApplicationStates.LeaseApplication]: {
        initial: 'Select',
        states: {
          Select: {
            always: [
              { target: ExistingApplicationStates.Signed, cond: 'AppFinished' },
              { target: '#ExistingApplication.ApprovalConfirmation', cond: 'LeaseSkipToApprovalConfirmation' },
              { target: '#ExistingApplication.ApprovalConfirmation', cond: 'LeaseBrickAndMortarApproved' },
              { target: '#ExistingApplication.WelcomeBack', cond: 'ShowWelcomeBack' },
              { target: ExistingApplicationStates.BankDetails, cond: 'LeaseSkipToBankDetails' },
              { target: ExistingApplicationStates.DebitCardEntry, cond: 'LeaseSkipToDebitCardEntry' },
              { target: ExistingApplicationStates.LeaseAgreement, cond: 'LeaseSkipToLeaseAgreement' },
              { target: ExistingApplicationStates.LeaseAgreement, cond: 'LeaseDocsReady' },
              { target: ExistingApplicationStates.ContractSummary, cond: 'LeaseOTBNonVC' },
              { target: ExistingApplicationStates.ContractSummary, cond: 'LeaseApproved' },
              { target: ExistingApplicationStates.BankAccountError, cond: 'LeaseBankAccountError' },
              { target: ExistingApplicationStates.DebitCardError, cond: 'LeasePaymentError' },
              { target: ExistingApplicationStates.LeaseDecline, cond: 'LeaseDecline' },
              { target: ExistingApplicationStates.PayFrequency },
            ],
          },
          [ExistingApplicationStates.Signed]: {
            type: 'final',
          },
          [ExistingApplicationStates.PayFrequency]: {
            on: {
              [ExistingApplicationEvents.NEXT]: [
                { target: ExistingApplicationStates.BankDetails, cond: 'PerformBav' },
                //  If OTB lease flow, either go to contract summary or debit entry
                { target: ExistingApplicationStates.ContractSummary, cond: 'isLeaseOTB' },
                { target: ExistingApplicationStates.DebitCardEntry, cond: 'IsCardExpired' },
                // Debit Card entry is the default flow
                { target: ExistingApplicationStates.DebitCardEntry },
              ],
            },
            meta: {
              navigationSection: NavigationSections.PAYMENT_DETAILS,
            },
          },
          [ExistingApplicationStates.BankAccountError]: {
            on: {
              [ExistingApplicationEvents.NEXT]: {
                target: ExistingApplicationStates.BankDetails,
              },
            },
            meta: {
              navigationSection: NavigationSections.PAYMENT_DETAILS,
            },
          },
          [ExistingApplicationStates.BankDetails]: {
            on: {
              [ExistingApplicationEvents.NEXT]: [
                {
                  target: '#ExistingApplication.BankAccountHardDecline',
                  cond: 'BankAccountHardDecline',
                },
                {
                  target: ExistingApplicationStates.DebitCardEntry,
                },
              ],
              [ExistingApplicationEvents.BACK]: {
                target: ExistingApplicationStates.PayFrequency,
              },
            },
            meta: {
              navigationSection: NavigationSections.PAYMENT_DETAILS,
              canGoBackViaHeader: true,
            },
          },
          [ExistingApplicationStates.ContractSummary]: {
            on: {
              [ExistingApplicationEvents.NEXT]: {
                target: ExistingApplicationStates.LeaseAgreement,
              },
              [ExistingApplicationEvents.EDIT_DEBIT_INFO]: {
                target: ExistingApplicationStates.DebitCardEntry,
              },
            },
            meta: {
              navigationSection: NavigationSections.REVIEW_AND_PAY,
            },
          },
          [ExistingApplicationStates.LeaseAgreement]: {
            meta: {
              navigationSection: NavigationSections.REVIEW_AND_PAY,
            },
          },
          [ExistingApplicationStates.DebitCardError]: {
            on: {
              [ExistingApplicationEvents.NEXT]: {
                target: ExistingApplicationStates.DebitCardEntry,
              },
            },
            meta: {
              navigationSection: NavigationSections.PAYMENT_DETAILS,
            },
          },
          [ExistingApplicationStates.DebitCardEntry]: {
            on: {
              [ExistingApplicationEvents.NEXT]: [
                { target: '#ExistingApplication.DebitCardHardDecline', cond: 'DebitCardHardDecline' },
                {
                  target: '#ExistingApplication.DebitCardSoftDecline',
                  cond: 'DebitCardSoftDecline',
                },
                { target: '#ExistingApplication.DebitCardFatalError', cond: 'DebitCardFatalError' },
                { target: '#ExistingApplication.ApprovalConfirmation', cond: 'IsBrickAndMortar' },
                { target: ExistingApplicationStates.ContractSummary },
              ],
              [ExistingApplicationEvents.BACK]: [
                { target: ExistingApplicationStates.BankDetails, cond: 'PerformBav' },
                { target: ExistingApplicationStates.PayFrequency },
              ],
            },
            meta: {
              navigationSection: NavigationSections.PAYMENT_DETAILS,
              canGoBackViaHeader: true,
            },
          },
          [ExistingApplicationStates.LeaseDecline]: {
            type: 'final',
          },
        },
        on: {
          [ExistingApplicationEvents.NEXT]: {
            target: '#ExistingApplication.FinalApproval',
          },
        },
      },
      [ExistingApplicationStates.LoanApplication]: {
        initial: 'Select',
        states: {
          Select: {
            always: [
              { target: ExistingApplicationStates.LoanDecline, cond: 'LoanDecline' },
              { target: '#ExistingApplication.WelcomeBack', cond: 'ShowWelcomeBack' },
              { target: ExistingApplicationStates.PaymentSchedule },
            ],
          },
          [ExistingApplicationStates.PaymentSchedule]: {
            on: {
              [ExistingApplicationEvents.NEXT]: {
                target: ExistingApplicationStates.AutoPay,
              },
              [ExistingApplicationEvents.CREDIT_PULL]: {
                target: ExistingApplicationStates.CreditPullAuth,
              },
            },
            meta: {
              navigationSection: NavigationSections.REVIEW_AND_PAY,
            },
          },
          [ExistingApplicationStates.CreditPullAuth]: {
            on: {
              [ExistingApplicationEvents.BACK]: {
                target: ExistingApplicationStates.PaymentSchedule,
              },
            },
          },
          [ExistingApplicationStates.AutoPay]: {
            on: {
              [ExistingApplicationEvents.NEXT]: {
                target: '#ExistingApplication.FinalApproval',
              },
            },
            meta: {
              navigationSection: NavigationSections.REVIEW_AND_PAY,
            },
          },
          [ExistingApplicationStates.LoanDecline]: {
            type: 'final',
          },
        },
      },
      [ExistingApplicationStates.VirtualCardApplication]: {
        initial: ExistingApplicationStates.Loading,
        states: {
          [ExistingApplicationStates.Loading]: {
            always: [
              { target: ExistingApplicationStates.VirtualCardPage, cond: 'AppFinished' },
              { target: ExistingApplicationStates.CartSummary, cond: 'LeaseSkipToCartSummary' },
              { target: ExistingApplicationStates.ContractSummary, cond: 'LeaseDocsReady' },
              { target: ExistingApplicationStates.CheckLeaseStatus },
            ],
            meta: {
              navigationSection: NavigationSections.HIDE_PROGRESS_BAR,
            },
          },
          [ExistingApplicationStates.Prequalification]: {
            on: {
              [ExistingApplicationEvents.NEXT]: [
                {
                  actions: [
                    actions.assign({
                      order: (context, event) => event?.data?.order,
                      appMode: (context, event) => event?.data?.appMode,
                    }),
                  ],
                  target: ExistingApplicationStates.PayFrequency,
                },
              ],
            },
            meta: {
              navigationSection: NavigationSections.HIDE_PROGRESS_BAR,
            },
          },
          [ExistingApplicationStates.CheckLeaseStatus]: {
            always: [
              { target: ExistingApplicationStates.Approval, cond: 'LeaseApproved' },
              { target: ExistingApplicationStates.BankAccountError, cond: 'LeaseBankAccountError' },
              { target: ExistingApplicationStates.DebitCardError, cond: 'LeasePaymentError' },
              { target: ExistingApplicationStates.PayFrequency, cond: 'SkipApproval' },
              { target: ExistingApplicationStates.Prequalification, cond: 'LeasePreApproved' },
              { target: ExistingApplicationStates.PayFrequency },
            ],
          },
          [ExistingApplicationStates.PayFrequency]: {
            on: {
              [ExistingApplicationEvents.NEXT]: [
                { target: ExistingApplicationStates.BankDetails, cond: 'PerformBav' },
                { target: ExistingApplicationStates.DebitCardEntry },
              ],
            },
            meta: {
              navigationSection: NavigationSections.PAYMENT_DETAILS,
            },
          },
          [ExistingApplicationStates.BankAccountError]: {
            on: {
              [ExistingApplicationEvents.NEXT]: {
                target: ExistingApplicationStates.BankDetails,
              },
            },
            meta: {
              navigationSection: NavigationSections.PAYMENT_DETAILS,
            },
          },
          [ExistingApplicationStates.BankDetails]: {
            on: {
              [ExistingApplicationEvents.NEXT]: [
                {
                  target: '#ExistingApplication.BankAccountHardDecline',
                  cond: 'BankAccountHardDecline',
                },
                {
                  target: ExistingApplicationStates.DebitCardEntry,
                },
              ],
              [ExistingApplicationEvents.BACK]: {
                target: ExistingApplicationStates.PayFrequency,
              },
            },
            meta: {
              navigationSection: NavigationSections.PAYMENT_DETAILS,
              canGoBackViaHeader: true,
            },
          },
          [ExistingApplicationStates.DebitCardError]: {
            on: {
              [ExistingApplicationEvents.NEXT]: {
                target: ExistingApplicationStates.DebitCardEntry,
              },
            },
            meta: {
              navigationSection: NavigationSections.PAYMENT_DETAILS,
            },
          },
          [ExistingApplicationStates.DebitCardEntry]: {
            on: {
              [ExistingApplicationEvents.NEXT]: [
                {
                  target: '#ExistingApplication.DebitCardHardDecline',
                  cond: 'DebitCardHardDecline',
                },
                {
                  target: '#ExistingApplication.DebitCardSoftDecline',
                  cond: 'DebitCardSoftDecline',
                },
                {
                  target: '#ExistingApplication.DebitCardFatalError',
                  cond: 'DebitCardFatalError',
                },
                { target: ExistingApplicationStates.ContractSummary, cond: 'isLeaseOTB' },
                { target: ExistingApplicationStates.Approval },
              ],
              [ExistingApplicationEvents.BACK]: [
                { target: ExistingApplicationStates.BankDetails, cond: 'PerformBav' },
                { target: ExistingApplicationStates.PayFrequency },
              ],
            },
            meta: {
              navigationSection: NavigationSections.PAYMENT_DETAILS,
              canGoBackViaHeader: true,
            },
          },
          [ExistingApplicationStates.Approval]: {
            on: {
              [ExistingApplicationEvents.NEXT]: [
                {
                  // If the order has items, then the customer must have already confirmed their cart total so they should go to contract summary next
                  actions: [
                    actions.assign({
                      order: (context, event) => event?.data?.order,
                      appMode: (context, event) => event?.data?.appMode,
                    }),
                  ],
                  target: ExistingApplicationStates.ContractSummary,
                  cond: 'OrderHasItems',
                },
                {
                  actions: [
                    actions.assign({
                      order: (context, event) => event?.data?.order,
                      appMode: (context, event) => event?.data?.appMode,
                    }),
                  ],
                  target: ExistingApplicationStates.ItemEntry,
                },
              ],
            },
            meta: {
              canGoBackViaHeader: false,
            },
          },
          [ExistingApplicationStates.ItemEntry]: {
            on: {
              [ExistingApplicationEvents.BACK]: {
                actions: [
                  actions.assign({
                    canGoBackViaHeader: false,
                  }),
                ],
                target: ExistingApplicationStates.CartSummary,
              },
              [EcommMachineEvents.NEXT]: {
                actions: [
                  actions.assign({
                    canGoBackViaHeader: false,
                  }),
                ],
                target: ExistingApplicationStates.CartSummary,
              },
            },
            meta: {
              navigationSection: NavigationSections.PAYMENT_DETAILS,
            },
          },
          [ExistingApplicationStates.CartSummary]: {
            on: {
              [ExistingApplicationEvents.BACK]: [
                {
                  actions: [
                    actions.assign({
                      canGoBackViaHeader: true,
                    }),
                  ],
                  target: ExistingApplicationStates.ItemEntry,
                  cond: 'HasItems',
                },
                {
                  target: ExistingApplicationStates.ItemEntry,
                },
              ],
              [EcommMachineEvents.NEXT]: {
                target: ExistingApplicationStates.CartTotal,
              },
            },
            meta: {
              navigationSection: NavigationSections.PAYMENT_DETAILS,
            },
          },
          [ExistingApplicationStates.CartTotal]: {
            on: {
              [ExistingApplicationEvents.BACK]: {
                target: ExistingApplicationStates.CartSummary,
              },
              [EcommMachineEvents.NEXT]: {
                target: ExistingApplicationStates.ContractSummary,
              },
            },
            meta: {
              navigationSection: NavigationSections.PAYMENT_DETAILS,
              canGoBackViaHeader: true,
            },
          },
          [ExistingApplicationStates.ContractSummary]: {
            on: {
              [ExistingApplicationEvents.BACK]: {
                target: ExistingApplicationStates.CartSummary,
              },
              [EcommMachineEvents.NEXT]: {
                target: ExistingApplicationStates.LeaseAgreement,
              },
              [ExistingApplicationEvents.EDIT_DEBIT_INFO]: {
                target: ExistingApplicationStates.DebitCardEntry,
              },
            },
            meta: {
              navigationSection: NavigationSections.REVIEW_AND_PAY,
            },
          },
          [ExistingApplicationStates.LeaseAgreement]: {
            on: {
              [ExistingApplicationEvents.BACK]: {
                target: ExistingApplicationStates.ContractSummary,
              },
              [EcommMachineEvents.NEXT]: {
                target: ExistingApplicationStates.VirtualCardPage,
              },
            },
            meta: {
              navigationSection: NavigationSections.REVIEW_AND_PAY,
            },
          },
          [ExistingApplicationStates.VirtualCardPage]: {
            on: {
              [ExistingApplicationEvents.BACK]: {
                target: ExistingApplicationStates.CartSummary,
              },
              [EcommMachineEvents.NEXT]: {
                target: ExistingApplicationStates.AuthorizedUserTerms,
              },
            },
          },
          [ExistingApplicationStates.AuthorizedUserTerms]: {
            on: {
              [ExistingApplicationEvents.BACK]: {
                target: ExistingApplicationStates.VirtualCardPage,
              },
            },
          },
        },
      },
      [ExistingApplicationStates.FinalApproval]: {},
      [ExistingApplicationStates.WelcomeBack]: {
        on: {
          [ExistingApplicationEvents.NEXT]: [
            {
              target: ExistingApplicationStates.LeaseApplication,
              cond: 'AppModeLease',
              actions: assign({
                skipToPage: null,
              }),
            },
            {
              target: ExistingApplicationStates.LeaseApplication,

              cond: 'DebitCardPage',
              actions: assign({
                skipToPage: 'DebitCardEntry',
              }),
            },
            {
              target: ExistingApplicationStates.LoanApplication,
              cond: 'AppModeLoan',
              actions: assign({
                skipToPage: null,
              }),
            },
          ],
        },
        meta: {
          navigationSection: NavigationSections.HIDE_PROGRESS_BAR,
        },
      },
      [ExistingApplicationStates.CartValidation]: {
        meta: {
          navigationSection: NavigationSections.HIDE_PROGRESS_BAR,
        },
      },
      [ExistingApplicationStates.DebitCardHardDecline]: {},
      [ExistingApplicationStates.DebitCardSoftDecline]: {
        on: {
          [ExistingApplicationEvents.NEXT]: [
            {
              target: '#ExistingApplication.VirtualCardApplication.DebitCardEntry',
              cond: 'VirtualCardDealer',
            },
            { target: '#ExistingApplication.LeaseApplication.DebitCardEntry' },
          ],
        },
      }, // needs to go back to IP
      [ExistingApplicationStates.DebitCardFatalError]: {},
      [ExistingApplicationStates.BankAccountHardDecline]: {},
      [ExistingApplicationStates.ApprovalConfirmation]: {},
    },
    schema: {
      context: {} as ExistingApplicationMachineContext,
      events: {} as ExistingApplicationMachineEvents,
      guards: {} as ExistingApplicationMachineGuards,
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  },
  {
    guards: {
      AppModeLease: (context: ExistingApplicationMachineContext) => {
        if (!!context.appMode) {
          return context.appMode === AppMode.lease;
        } else {
          return !!context.order?.application?.lease;
        }
      },
      AppModeLoan: (context: ExistingApplicationMachineContext) => {
        if (!!context.appMode) {
          return context.appMode === AppMode.loan;
        } else {
          return !!context.order?.application?.loan && !context.order?.application?.lease;
        }
      },
      VirtualCardDealer: (context: ExistingApplicationMachineContext) => !!context?.order?.dealer?.isVirtualCard,
      // Changing this to always performBAV if the flag
      PerformBav: (context, event) =>
        context?.order?.application?.lease?.draftStep === 'bankingInformation' || // normal value
        context?.order?.application?.lease?.draftStep === 'bankAccount', // value if in errors found
      DebitCardPage: (context, event) =>
        context?.order?.application?.lease?.draftStep === 'debitCardInformation' || // normal value
        context?.order?.application?.lease?.draftStep === 'debitCardError', // value if in errors found
      PaymentError: (context: ExistingApplicationMachineContext) => {
        const declineReason = context?.order?.application?.lease?.declineReason;
        return ['debitCardError'].includes(declineReason!);
      },
      SkipApproval: (context: ExistingApplicationMachineContext) => context.skipApproval,
      ShowWelcomeBack: (context: ExistingApplicationMachineContext) => context.skipToPage === 'WelcomeBack',
      LoanSkipToContractSummary: (context: ExistingApplicationMachineContext) => context.skipApproval,
      LeaseSkipToBankDetails: (context: ExistingApplicationMachineContext) => context.skipToPage === 'BankDetails',
      LeaseSkipToDebitCardEntry: (context: ExistingApplicationMachineContext) =>
        context.skipToPage === 'DebitCardEntry',
      LeaseSkipToLeaseAgreement: (context: ExistingApplicationMachineContext) =>
        context.skipToPage === 'LeaseAgreement',
      LeaseSkipToCartSummary: (context: ExistingApplicationMachineContext) => context.skipToPage === 'CartSummary',
      LeasePreApproved: (context: ExistingApplicationMachineContext) =>
        context?.order?.application?.lease?.status === 'preApproved',
      LeaseApproved: (context: ExistingApplicationMachineContext) =>
        context?.order?.application?.lease?.status === 'approved',
      LeaseDocsReady: (context: ExistingApplicationMachineContext) =>
        context?.order?.application?.lease?.status === 'docsReady',
      LeaseDocsSigned: (context: ExistingApplicationMachineContext) =>
        context?.order?.application?.lease?.status === 'docsSigned',
      LeaseReadyForFunding: (context: ExistingApplicationMachineContext) =>
        context?.order?.application?.lease?.status === 'readyForFunding',
      LeasePaymentError: (context: ExistingApplicationMachineContext) => {
        const declineReason = context?.order?.application?.lease?.declineReason;
        return ['creditCardNumber', 'creditCardExpiration', 'debitCardError'].includes(declineReason!);
      },
      LeaseBankAccountError: (context: ExistingApplicationMachineContext) => {
        const declineReason = context?.order?.application?.lease?.declineReason;
        return declineReason === 'bankAccount';
      },
      LeaseOTBNonVC: (context: ExistingApplicationMachineContext) =>
        !!context?.order?.application?.lease?.isOtb && !context?.order?.dealer?.isVirtualCard,
      IsBrickAndMortar: (context, event) => {
        return context.order?.dealer?.channelType === ChannelTypes.BrickAndMortar;
      },
      LeaseBrickAndMortarApproved: (context, event) =>
        (context?.order?.application?.lease?.status === 'approved' ||
          context?.order?.application?.lease?.status === 'docsReady' ||
          context?.order?.application?.lease?.status === 'docsSigned') &&
        context?.order?.dealer?.channelType === ChannelTypes.BrickAndMortar,
      LeaseSkipToApprovalConfirmation: (context: ExistingApplicationMachineContext) =>
        context.skipToPage === 'ApprovalConfirmation',
      IsCardExpired: (context: ExistingApplicationMachineContext) =>
        !!context?.order?.application?.lease?.card?.isExpired,
      isLeaseOTB: (context: ExistingApplicationMachineContext) =>
        !!context?.order?.application?.lease?.isOtb && !context?.order?.application?.lease?.card?.isExpired,
      LoanDecline: (context: ExistingApplicationMachineContext) => {
        return context.order?.application?.loan?.status === 'declined';
      },
      LeaseDecline: (context: ExistingApplicationMachineContext) => {
        return context.order?.application?.lease?.status === 'declined';
      },
      AppFinished: (context: ExistingApplicationMachineContext) => {
        return (
          context.order?.application?.lease?.status === 'docsSigned' ||
          context.order?.application?.lease?.status === 'approvedForFunding' ||
          context.order?.application?.lease?.status === 'readyForFunding' ||
          context.order?.application?.lease?.status === 'funded' ||
          context.order?.application?.loan?.status === 'delivered' ||
          context.order?.application?.loan?.status === 'accepted'
        );
      },
      OrderHasItems: (context: ExistingApplicationMachineContext, event: any) =>
        !!event?.data?.order?.details?.items?.length,
      HasItems: (context: ExistingApplicationMachineContext, event: any) => event.hasItems,
      DebitCardHardDecline: (context: ExistingApplicationMachineContext, event: any) =>
        event.data.updateDebitInfoErrors &&
        event.data.updateDebitInfoErrors.length > 0 &&
        event.data.updateDebitInfoErrors.some((err: UpdateDebitErrors) => err.__typename === 'HardDecline'),
      DebitCardSoftDecline: (context: ExistingApplicationMachineContext, event: any) =>
        event.data.updateDebitInfoErrors &&
        event.data.updateDebitInfoErrors.length > 0 &&
        event.data.updateDebitInfoErrors.some((err: UpdateDebitErrors) => err.__typename === 'SoftDecline'),
      DebitCardFatalError: (context: ExistingApplicationMachineContext, event: any) =>
        event.data.updateDebitInfoErrors &&
        event.data.updateDebitInfoErrors.length > 0 &&
        event.data.updateDebitInfoErrors.some((err: UpdateDebitErrors) => err.__typename !== 'PrepaidCardError'),
      BankAccountHardDecline: (context, event) =>
        event.data.updateBankAccountErrors &&
        event.data.updateBankAccountErrors.length > 0 &&
        event.data.updateBankAccountErrors.some((err: UpdateBankAccountErrors) => err.__typename === 'HardDecline'),
    },
  },
);

import styled from '@emotion/styled';
import { useMachine } from '@xstate/react';
import React, { useEffect, useState } from 'react';
import FlowContainerWrapper from '../../components/flowContainerWrapper/FlowContainerWrapper';
import Footer from '../../components/footer';
import Header from '../../components/header/Header';
import FlowContainer from '../../pages/flowContainer/Machine-Container';
import ecommMachine from '../../state-machine/ecomm-machine';
import { AppMode } from '../../stores/order.store';
import { Order } from '../../gql/graphql';

const ContentStyling = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1.5rem 2rem 2.25rem; /* The last number (bottom margin) needs to match height of the footer  */
  flex: 1;
`;

//LAuren - TODO > revert this
// @ts-nocheck
type AppContentProps = { orderDetails: Order; orderID: string; appMode: AppMode };
const AppContent = ({ orderDetails, orderID, appMode }: AppContentProps) => {
  const machine = useMachine(ecommMachine(orderID, orderDetails, appMode));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_state, transition, interpreter] = machine;

  useEffect(() => {
    transition('INIT', {});
  }, [transition]);

  // useEffect(() => {
  //   interpreter
  //     .onTransition((state) => console.log('PARENT TRANSITION', state))
  //     .onEvent((event) => console.log('PARENT EVENT', event));
  // }, [interpreter]);

  const [headerGoBackFunc, setHeaderGoBackFunc] = useState<undefined | (() => void)>(undefined);
  const [currentNode, setCurrentNode] = useState<[string] | undefined>(undefined);

  return (
    <div id="app-content-container" style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', flex: '1' }}>
      <Header dealerId={orderDetails.dealer.id} goBackFunc={headerGoBackFunc} currentNode={currentNode} />

      <ContentStyling>
        <FlowContainerWrapper>
          <FlowContainer
            // state={state}
            send={transition}
            setHeaderGoBackFunc={setHeaderGoBackFunc}
            setCurrentNode={setCurrentNode}
            machine={machine}
          />
        </FlowContainerWrapper>
      </ContentStyling>
      <Footer />
    </div>
  );
};

export default React.memo(AppContent);

// Data converters for presentation to the user

// Add commas to numbers or strings: "1000" -> "1,000"
export const convertToCurrency = (value: number, fractionDigits = 2) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

  return formatter.format(value);
};

import { assign, createMachine } from 'xstate';
import { ChannelTypes, Order } from '../gql/graphql';
import { acceptedDomain } from '../helpers';
import { AppMode } from '../stores/order.store';
import CustomerLookupMachine from './customer-lookup';
import draftApplicationMachine from './draft-application-machine';
import EntryPointMachine from './entrypoint-machine';
import ExistingApplicationMachine from './existing-application';
import NewApplicationMachine from './new-application';
import OtbMachine from './otb';
import {
  EcommMachineEvents,
  EcommMachineMachineContext,
  EcommMachineMachineStates,
  EcommMachineStates,
} from './types/ecommMachine.types';
import { ExistingApplicationMachineContext } from './types/existingApplication.types';
import VirtualCardMachine from './virtual-card';

const ecommMachine = (orderID: string, orderDetails: Order, appMode: AppMode) => {
  // <CustomerLookupMachineContext, CustomerLookupMachineEvents, CustomerLookupMachineStates>(
  return createMachine<EcommMachineMachineContext, any, EcommMachineMachineStates>(
    {
      id: 'ECommerceMachine',
      initial: EcommMachineStates.EcommMachine,
      context: {
        orderId: orderID,
        order: orderDetails,
        customerLookupStartingPage: null,
        existingApplicationStartingPage: null,
        appMode: appMode,
        entryPointPage: 'OTPVerification',
      },
      states: {
        [EcommMachineStates.EcommMachine]: {
          id: 'EcommMachine',
          // What is the point of only 1 substate....
          // Please remove this.
          initial: EcommMachineStates[EcommMachineStates.Loading],
          states: {
            [EcommMachineStates.Loading]: {
              entry: [],
              exit: [
                assign({
                  order: (_, { order }) => order,
                  appMode: (_, { appMode }) => appMode,
                }),
                'postLoadingDone',
              ],
              tags: 'Loading',
              on: {
                [EcommMachineEvents.APP_IN_PROGRESS]: [
                  { target: '#ECommerceMachine.ExistingApplication', cond: 'VirtualCardDealer' },
                  { target: '#ECommerceMachine.DraftApplication', cond: 'DraftApp' },
                  {
                    target: '#ECommerceMachine.EntryPoint',
                    cond: 'EntryPointApprovalConfirmation',
                    actions: assign({
                      entryPointPage: (_context) => 'ApprovalConfirmation',
                    }),
                  },
                  {
                    target: '#ECommerceMachine.EntryPoint',
                    cond: 'EntryPointContractSummary',
                    actions: assign({
                      entryPointPage: (_context) => 'ContractSummary',
                    }),
                  },
                  {
                    target: '#ECommerceMachine.EntryPoint',
                    cond: 'LeaseBankAccountError',
                    actions: assign({
                      entryPointPage: (_context, _event) => 'BankAccountError',
                    }),
                  },
                  {
                    target: '#ECommerceMachine.EntryPoint',
                    cond: 'LeaseDebitCardError',
                    actions: assign({
                      entryPointPage: (_context, _event) => 'DebitCardError',
                    }),
                  },
                  {
                    target: '#ECommerceMachine.EntryPoint',
                    actions: assign({
                      entryPointPage: (_context, _event) => 'WelcomeBack',
                    }),
                  },
                ],
                [EcommMachineEvents.ERRROR]: [{ actions: 'postLoadingDone' }],
                [EcommMachineEvents.APP_FINISHED]: [
                  {
                    target: '#ECommerceMachine.EntryPoint',
                    actions: assign({
                      entryPointPage: (_context, _event) => 'Done',
                    }),
                  },
                ],
                [EcommMachineEvents.CUSTOMER_LOOKUP_EVENT]: {
                  target: '#ECommerceMachine.EntryPoint',
                  actions: assign({
                    entryPointPage: (_context) => 'OTPVerification',
                  }),
                },
                [EcommMachineEvents.DECLINE]: {
                  target: '#ECommerceMachine.EntryPoint',
                  actions: assign({
                    entryPointPage: (context) => (context.appMode === AppMode.loan ? 'LoanDecline' : 'LeaseDecline'),
                  }),
                },
              },
              invoke: {
                src:
                  ({ orderId, order, appMode }) =>
                  async (cb) => {
                    try {
                      if (!!order) {
                        // Leaving this here because without redux, this is where I'd want to fetch the order
                        // const response = await fetchOrder(orderId);
                        const getEventType = DetermineOrderPath(order);
                        // Again, leaving this here. Not necessary since we already have the order
                        // However, when we fetch the order from the machine will need to return this
                        cb({
                          type: getEventType,
                          order,
                          appMode,
                        });
                      } else {
                        throw Error('User Data is null');
                      }
                    } catch (e) {
                      console.log(e);
                      // cb({ type: UpdateEvents.ERROR });
                    }
                  },
              },
            },
          },
        },
        [EcommMachineStates.EntryPoint]: {
          invoke: {
            id: 'EntryPointMachine',
            src: EntryPointMachine,
            data: {
              page: (ctx: EcommMachineMachineContext) => ctx.entryPointPage,
              order: (ctx: EcommMachineMachineContext) => ctx.order,
            },
          },
          on: {
            [EcommMachineEvents.CUSTOMER_LOOKUP_EVENT]: {
              target: EcommMachineStates.CustomerLookupFlow,
              actions: assign({
                customerLookupStartingPage: (_context, _event) => 'OTPCheck',
              }),
            },
            [EcommMachineEvents.EXISTING_APPLICATION]: {
              target: EcommMachineStates.ExistingApplication,
              actions: assign({
                existingApplicationStartingPage: (_context, event) => {
                  switch (event?.data?.source) {
                    case 'WelcomeBack.NEXT':
                      return null;
                    case 'ContractSummary.NEXT':
                      return 'LeaseAgreement';
                    case 'ContractSummary.BACK':
                      return 'CartSummary';
                    case 'BankAccountError.NEXT':
                      return 'BankDetails';
                    case 'DebitCardError.NEXT':
                      return 'DebitCardEntry';
                    case 'ContractSummary.EDIT_DEBIT_INFO':
                      return 'DebitCardEntry';
                  }
                },
              }),
            },
          },
        },
        [EcommMachineStates.ExistingApplication]: {
          invoke: {
            id: 'ExistingApplicationMachine',
            src: ExistingApplicationMachine,
            data: {
              order: (ctx: ExistingApplicationMachineContext) => ctx.order,
              appMode: (ctx: ExistingApplicationMachineContext) => ctx.appMode,
              skipApproval: (_context: any, event: any) => !!event?.data?.skipApproval,
              skipToPage: (ctx: EcommMachineMachineContext) => ctx.existingApplicationStartingPage,
            },
          },
          on: {
            [EcommMachineEvents.VIRTUAL_CARD_APP]: {
              target: '#ECommerceMachine.VirtualCard',
            },
          },
        },
        [EcommMachineStates.CustomerLookupFlow]: {
          invoke: {
            id: 'CustomerLookupMachine',
            src: CustomerLookupMachine,
            data: {
              order: (ctx: EcommMachineMachineContext) => ctx.order,
              skipToPage: (ctx: EcommMachineMachineContext) => ctx.customerLookupStartingPage,
            },
          },
          on: {
            [EcommMachineEvents.NEW_APPLICATION]: {
              target: '#ECommerceMachine.NewApplication',
            },
            [EcommMachineEvents.OTB_CUSTOMER]: {
              actions: assign({
                order: (_context, event) => event.data.order,
              }),
              target: '#ECommerceMachine.OTB',
            },
            [EcommMachineEvents.DRAFT_APP]: {
              actions: assign({
                order: (_context, event) => event.data.order,
              }),
              target: EcommMachineStates.DraftApplication,
            },
            [EcommMachineEvents.EXISTING_APPLICATION]: [
              {
                actions: assign({
                  order: (_context, event) => event?.data?.order,
                  appMode: (_context, event) => event?.data?.appMode,
                }),
                target: '#ECommerceMachine.ExistingApplication',
                cond: 'VirtualCardDealer',
              },
              {
                actions: assign({
                  order: (_context, event) => event?.data?.order,
                  appMode: (_context, event) => event?.data?.appMode,
                }),
                target: '#ECommerceMachine.ExistingApplication',
                cond: 'LeaseDebitCardError',
              },
              {
                actions: assign({
                  order: (_context, event) => event?.data?.order,
                  appMode: (_context, event) => event.data.appMode,
                  existingApplicationStartingPage: 'WelcomeBack',
                }),
                target: '#ECommerceMachine.ExistingApplication',
              },
            ],
          },
        },
        [EcommMachineStates.VirtualCard]: {
          invoke: {
            id: 'VirtualCardMachine',
            src: VirtualCardMachine,
            data: {
              order: orderDetails,
            },
          },
        },
        [EcommMachineStates.OTB]: {
          invoke: {
            id: 'OtbMachine',
            src: OtbMachine,
            data: {
              order: (ctx: EcommMachineMachineContext, _event: any) => ctx.order,
              customerFlowType: (_ctx: EcommMachineMachineContext, event: any) => event.data.customerFlowType,
            },
          },
          on: {
            [EcommMachineEvents.VIRTUAL_CARD_APP]: {
              target: '#ECommerceMachine.VirtualCard',
            },
            [EcommMachineEvents.EXISTING_APPLICATION]: {
              target: '#ECommerceMachine.ExistingApplication',
              actions: assign({
                order: (_context, event) => event?.data?.order,
                appMode: (_context, event) => event?.data?.appMode,
              }),
            },
            [EcommMachineEvents.NEW_APPLICATION]: {
              target: '#ECommerceMachine.NewApplication',
            },
          },
        },
        [EcommMachineStates.NewApplication]: {
          invoke: {
            id: 'NewApplicationMachine',
            src: NewApplicationMachine,
            data: (ctx: any) => ctx,
          },
          on: {
            [EcommMachineEvents.BACK]: {
              target: EcommMachineStates.CustomerLookupFlow,
              actions: assign({
                customerLookupStartingPage: (_context, _event) => 'BasicInfo',
              }),
            },
            [EcommMachineEvents.EXISTING_APPLICATION]: {
              target: '#ECommerceMachine.ExistingApplication',
              actions: [
                assign({
                  order: (_context, event) => event?.data?.order,
                  appMode: (_context, event) => event.data.appMode,
                }),
              ],
            },
            [EcommMachineEvents.VIRTUAL_CARD_APP]: {
              target: '#ECommerceMachine.VirtualCard',
            },
            [EcommMachineEvents.DECLINE]: {
              target: '#ECommerceMachine.Declined',
            },
          },
        },
        [EcommMachineStates.Declined]: {
          entry: [
            assign({
              appMode: (_, event) => event?.data?.appMode,
            }),
          ],
          always: [
            {
              target: '#ECommerceMachine.LoanDeclined',
              cond: 'LoanApp',
            },
            {
              target: '#ECommerceMachine.LeaseDeclined',
            },
          ],
        },
        [EcommMachineStates.LoanDeclined]: {},
        [EcommMachineStates.LeaseDeclined]: {},
        [EcommMachineStates.DraftApplication]: {
          invoke: {
            id: 'DraftApplicationMachine',
            src: draftApplicationMachine,
            data: {
              order: (ctx: EcommMachineMachineContext) => ctx.order,
            },
          },
        },
      },
      schema: {
        context: {} as EcommMachineMachineContext,
      },
      predictableActionArguments: true,
      preserveActionOrder: true,
    },
    {
      actions: {
        postLoadingDone: (context, _event) => {
          window.parent.postMessage(
            JSON.stringify({ stopSpinner: true }),
            acceptedDomain(context.order?.dealer?.domains!)!,
          );
        },
        // strictly for debugging, do not remove and make sure you clean up references in MRs
        log: (context, event) => {
          console.log('Main Ecomm Machine');
          console.log('context', context);
          console.log('event', event);
        },
      },
      guards: {
        DraftApp: (context) => context?.order?.application?.lease?.status === 'draft',
        LoanApp: (context) => context.appMode === AppMode.loan,
        VirtualCardDealer: (context, _event) => !!context.order?.dealer?.isVirtualCard,
        LeaseBankAccountError: (context, event) =>
          event?.data?.order?.application?.lease?.declineReason === 'bankAccount' ||
          context?.order?.application?.lease?.declineReason === 'bankAccount',
        LeaseDebitCardError: (context, event) =>
          event?.data?.order?.application?.lease?.declineReason === 'debitCardError' ||
          context?.order?.application?.lease?.declineReason === 'debitCardError',
        EntryPointApprovalConfirmation: (context, event) =>
          (context?.order?.application?.lease?.status === 'approved' ||
            context?.order?.application?.lease?.status === 'docsReady') &&
          context?.order?.dealer?.channelType === ChannelTypes.BrickAndMortar,
        EntryPointContractSummary: (context, event) => {
          // Should not skip if OTB (unless OTB + VC)
          if (!!context?.order?.application?.lease?.isOtb && !context?.order?.dealer?.isVirtualCard) {
            return false;
          } else {
            return (
              context?.order?.application?.lease?.status === 'docsReady' ||
              context?.order?.application?.lease?.status === 'approved'
            );
          }
        },
      },
    },
  );
};

const DetermineOrderPath = (order: Order): EcommMachineEvents => {
  const leaseAppId = order?.application?.lease?.id;
  const leaseAppStatus = order?.application?.lease?.status;
  const loanAppId = order?.application?.loan?.id;
  const loanAppStatus = order?.application?.loan?.status;
  const declineReason = order?.application?.lease?.declineReason;
  const isVirtualCard = order?.dealer?.isVirtualCard;
  // const errors = order?.error;

  if (leaseAppId && leaseAppStatus) {
    // Need to use OTB, VirtualCard, ExistingApplication or New Application....
    switch (leaseAppStatus) {
      case 'readyForFunding':
      case 'approvedForFunding':
      case 'funded':
      case 'docsSigned':
        return isVirtualCard ? EcommMachineEvents.APP_IN_PROGRESS : EcommMachineEvents.APP_FINISHED;
      case 'docsReady':
      case 'approved':
      case 'preApproved':
        return EcommMachineEvents.APP_IN_PROGRESS;
      case 'errorsFound':
        if (['creditCardNumber', 'creditCardExpiration', 'debitCardError', 'bankAccount'].includes(declineReason!)) {
          return EcommMachineEvents.APP_IN_PROGRESS;
        } else if (declineReason === 'address' || declineReason === 'ssn') {
          return EcommMachineEvents.CUSTOMER_LOOKUP_EVENT;
        }
        return EcommMachineEvents.ERRROR;
      case 'draft':
        return EcommMachineEvents.APP_IN_PROGRESS;
      case 'declined':
        return EcommMachineEvents.DECLINE;
      default:
        return EcommMachineEvents.ERRROR;
    }
  } else if (loanAppId && loanAppStatus) {
    switch (loanAppStatus) {
      case 'approved':
      case 'docs_ready':
      case 'accepted':
      case 'delivered':
        return EcommMachineEvents.APP_IN_PROGRESS;
      case 'errors_found':
      case 'draft':
        return EcommMachineEvents.CUSTOMER_LOOKUP_EVENT;
      case 'declined':
        return EcommMachineEvents.DECLINE;
      default:
        return EcommMachineEvents.ERRROR;
    }
  }
  return EcommMachineEvents.CUSTOMER_LOOKUP_EVENT;
};
export default ecommMachine;

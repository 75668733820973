import React from 'react';
import styled from '@emotion/styled';

import { useStoreState } from '../../hooks/easyPeasyHooks';
import { useTrack } from '../../hooks/segment';
import { useReportError } from '../../hooks/dtrum';
import { fontFamilies, colors } from '../baseStyle/Variables';
import Header from '../header/Header';
import Message from '../message/Message';
import { useEffect } from 'react';
import { currentDomain } from '../../helpers/window/window.helpers';
import { modalLoaderHelper } from '../../helpers/modal/modal.helper';

const SecurityErrorWrapper = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  font-family: ${fontFamilies.gilroyRegular};
  justify-content: left;
  margin: 0 auto;
  padding: 1rem 1rem;

  span {
    text-decoration: underline;
    color: ${colors.koalafiBlue};
  }

  p {
    font-size: 20px;
    line-height: 24px;
  }
`;

export enum SecurityErrorType {
  Forbidden403,
  Unauthorized401,
}

type Props = {
  error?: any;
  errorType: SecurityErrorType;
};

const CustomSecurityErrorBoundary: React.FC<Props> = (props: Props) => {
  let { error, errorType } = props;
  const reportErr = useReportError();
  const { order } = useStoreState((state) => state.order);
  const track = useTrack();

  if (error) {
    reportErr(error);
    console.error(error);
    console.error(error?.stack);
    console.trace();
  }

  const formattedNumberMsg = () => (
    <>
      Try again or reach out to our support team at <span>844.937.8275</span> for help.{' '}
    </>
  );

  let headerMsg: string;
  let displayMsg: string | JSX.Element;
  let segmentMsg: string;
  if (errorType === SecurityErrorType.Unauthorized401) {
    headerMsg = 'You’ve been logged out';
    let storeNameStr = order?.dealer?.storeName ? ` at ${order?.dealer?.storeName}` : '';
    displayMsg = `We saved your application progress${storeNameStr}. You can continue your application by first verifying your identity.`;
    segmentMsg = '401 unauthorized';
  } else {
    // will be 403 if not 401
    headerMsg = 'Something went wrong';
    displayMsg = formattedNumberMsg();
    segmentMsg = '403 access forbidden';
  }

  const handleClick = () => {
    track('SecurityErrorBoundary - user restarted session');

    // add parentDomain query param if we are in a modal and query param doesn't already exist (can't successfully set queryParam after reload)
    if (window.location !== window.parent.location) {
      const parentDomainQueryParam = new URLSearchParams(document.location.search.substring(1)).get('parentDomain');
      if (!parentDomainQueryParam) {
        const url = new URL(document.location.href);
        url.searchParams.set('parentDomain', document.referrer);
        window.history.pushState({}, '', url);
      }

      // start SDK spinner (as UI spinner doesn't show in a modal)
      const sendDomain = order?.dealer?.domains || [currentDomain()]; // Using current domain if domains not loaded
      modalLoaderHelper({ startSpinner: true }, sendDomain);
    }

    window.location.reload();
  };

  useEffect(() => {
    track('SecurityErrorBoundary - ' + segmentMsg, { ...order, error });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header goBackFunc={undefined} currentNode={['CustomSecurityErrorBoundary']} />
      <SecurityErrorWrapper id="SecurityErrorBoundary">
        <Message
          heading={headerMsg}
          body={displayMsg}
          shouldShowButton={errorType === SecurityErrorType.Unauthorized401}
          buttonText={'Continue application'}
          buttonClick={handleClick}
        />
      </SecurityErrorWrapper>
    </>
  );
};

export default CustomSecurityErrorBoundary;

import { assign, createMachine } from 'xstate';
import { NavigationSections } from '../constants';
import { transferToRapid } from '../helpers/cookies/unified.helper';
import {
  DraftEvents,
  DraftMachineContext,
  DraftMachineEvents,
  DraftMachineGuards,
  DraftMachineStates,
  DraftStates,
} from './types/draftMachine.types';

export default createMachine<DraftMachineContext, DraftMachineEvents, DraftMachineStates>(
  {
    id: 'Draft',
    initial: DraftStates.Entry,
    context: {
      order: null,
    },
    states: {
      // ephemeral state, used exclusively for immediate switching
      [DraftStates.Entry]: {
        always: [
          { target: DraftStates.PersonalInformation, cond: 'NoApplication' },
          { target: DraftStates.PersonalInformation, cond: 'AppStepPersonalInformation' },
          { target: DraftStates.ContactInformation, cond: 'AppStepContactData' },
          { target: DraftStates.IncomeSource, cond: 'AppStepIncomeSource' },
          { target: DraftStates.BankingInformation, cond: 'AppStepBankingInformation' },
          { target: DraftStates.DebitCardInformation, cond: 'AppStepDebitCardInformation' },
          { target: DraftStates.ReviewAndSubmit, cond: 'AppStepReviewAndSubmit' },
          { target: DraftStates.AmendAndResubmit, cond: 'AppStepAmendAndResubmit' },
        ],
      },
      [DraftStates.PersonalInformation]: {
        on: {
          [DraftEvents.NEXT]: {
            target: DraftStates.ContactInformation,
          },
        },
        meta: {
          navigationSection: NavigationSections.CHECK_ELIGIBILITY,
        },
      },
      [DraftStates.ContactInformation]: {
        on: {
          [DraftEvents.NEXT]: {
            target: DraftStates.BillingAddress,
          },
          [DraftEvents.BACK]: {
            target: [DraftStates.PersonalInformation],
          },
        },
        meta: {
          navigationSection: NavigationSections.CHECK_ELIGIBILITY,
          canGoBackViaHeader: true,
        },
      },
      [DraftStates.BillingAddress]: {
        // comes after basic info continue, but doesnt have an associated appStep
        on: {
          [DraftEvents.NEXT]: {
            target: DraftStates.IncomeSource,
            actions: assign({
              order: (context, event) => event.data.order,
            }),
          },
          [DraftEvents.VIEW_CUSTOMER_ID_PROGRAM]: {
            target: DraftStates.CustomerIdProgram,
          },
          [DraftEvents.VIEW_TERMS_AND_CONDITIONS]: {
            target: DraftStates.TermsAndConditions,
          },
          [DraftEvents.VIEW_PREQUALIFICATION_NOTICE]: {
            target: DraftStates.PrequalificationNotice,
          },
          [DraftEvents.VIEW_AUTHORIZED_USER_TERMS]: {
            target: DraftStates.AuthorizedUserTerms,
          },
        },
      },
      [DraftStates.IncomeSource]: {
        on: {
          [DraftEvents.NEXT]: [
            { target: DraftStates.BankingInformation }, // is bav turned off for any long form stores?
          ],
        },
        meta: {
          navigationSection: NavigationSections.PAYMENT_DETAILS,
        },
      },
      [DraftStates.BankingInformation]: {
        on: {
          [DraftEvents.NEXT]: [
            {
              target: DraftStates.DebitCardInformation,
            },
          ],
          [DraftEvents.BACK]: {
            target: DraftStates.IncomeSource,
          },
        },
        meta: {
          navigationSection: NavigationSections.PAYMENT_DETAILS,
          canGoBackViaHeader: true,
        },
      },
      [DraftStates.DebitCardInformation]: {
        on: {
          [DraftEvents.NEXT]: {
            actions: ['transferToRapid'],
          },
          [DraftEvents.BACK]: {
            target: DraftStates.BankingInformation,
          },
        },
        meta: {
          navigationSection: NavigationSections.PAYMENT_DETAILS,
          canGoBackViaHeader: true,
        },
      },
      [DraftStates.ReviewAndSubmit]: {
        entry: 'transferToRapid',
        // on: {
        //   [DraftEvents.NEXT]: {
        //     target: DraftStates.TransferToRapid,
        //   },
        //   [DraftEvents.BACK]: {
        //     // TODO: different event
        //     target: DraftStates.AmendAndResubmit,
        //   },
        // },
        // meta: {
        //   navigationSection: NavigationSections.REVIEW_AND_PAY,
        //   canGoBackViaHeader: true,
        // },
      },
      [DraftStates.AmendAndResubmit]: {
        // TODO: ignore until M3
        on: {
          [DraftEvents.NEXT]: {
            target: DraftStates.ReviewAndSubmit,
          },
        },
        meta: {
          navigationSection: NavigationSections.REVIEW_AND_PAY,
          canGoBackViaHeader: true,
        },
      },
      [DraftStates.TransferToRapid]: {
        // TODO: currently unused, use for milestone 3
        entry: [
          {
            type: 'transferToRapid',
          },
        ],
      },

      // info pages
      [DraftStates.CustomerIdProgram]: {
        on: {
          [DraftEvents.NEXT]: {
            target: DraftStates.BillingAddress,
          },
        },
      },
      [DraftStates.TermsAndConditions]: {
        on: {
          [DraftEvents.NEXT]: {
            target: DraftStates.BillingAddress,
          },
        },
      },
      [DraftStates.PrequalificationNotice]: {
        on: {
          [DraftEvents.NEXT]: {
            target: DraftStates.BillingAddress,
          },
        },
      },
      [DraftStates.AuthorizedUserTerms]: {
        on: {
          [DraftEvents.BACK]: {
            target: DraftStates.BillingAddress,
          },
        },
      },
    },
    schema: {
      context: {} as DraftMachineContext,
      events: {} as DraftMachineEvents,
      guards: {} as DraftMachineGuards,
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  },
  {
    actions: {
      transferToRapid: () => {
        transferToRapid();
      },
      // strictly for debugging, do not remove and make sure you clean up references in MRs
      log: (context, event) => {
        console.log('Draft Application');
        console.log('context', context);
        console.log('event', event);
      },
    },
    guards: {
      NoApplication: (context) => {
        return !context.order?.application?.lease?.id;
      },
      AppStepPersonalInformation: (context) => {
        return context.order?.application?.lease?.appStep === 'personalInformation';
      },
      AppStepContactData: (context) => {
        return context.order?.application?.lease?.appStep === 'contactData';
      },
      AppStepIncomeSource: (context) => {
        return context.order?.application?.lease?.appStep === 'incomeSource';
      },
      AppStepBankingInformation: (context) => {
        return context.order?.application?.lease?.appStep === 'bankingInformation';
      },
      AppStepDebitCardInformation: (context) => {
        return context.order?.application?.lease?.appStep === 'debitCardInformation';
      },
      AppStepReviewAndSubmit: (context) => {
        return context.order?.application?.lease?.appStep === 'reviewAndSubmit';
      },
      AppStepAmendAndResubmit: (context) => {
        return context.order?.application?.lease?.appStep === 'amendAndResubmit';
      },
    },
  },
);

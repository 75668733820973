import { EventObject, Typestate } from 'xstate';
import { Order } from '../../gql/graphql';

// Context Types
export type OtbMachineContext = {
  order: Order | null;
  customerFlowType: null | string;
  isRedirecting: boolean;
};

// State Types
export enum OtbStates {
  Entry = 'Entry',
  Approval = 'Approval',
  LastFour = 'LastFour',
  BasicInfo = 'BasicInfo',
  BasicsContinued = 'BasicsContinued',
  NewApplication = 'NewApplication',
  ExistingApplication = 'ExistingApplication',
  VirtualCard = 'VirtualCard',
  CustomerIdentification = 'CustomerIdentification',
  TermsAndConditions = 'TermsAndConditions',
  AuthorizedUserTerms = 'AuthorizedUserTerms',
  ApprovalConfirmation = 'ApprovalConfirmation',
}

export interface OtbMachineStatesSchema {
  [OtbStates.Entry]: {};
  [OtbStates.Approval]: {};
  [OtbStates.LastFour]: {};
  [OtbStates.BasicInfo]: {};
  [OtbStates.BasicsContinued]: {};
  [OtbStates.NewApplication]: {};
  [OtbStates.ExistingApplication]: {};
  [OtbStates.VirtualCard]: {};
  [OtbStates.CustomerIdentification]: {};
  [OtbStates.AuthorizedUserTerms]: {};
  [OtbStates.ApprovalConfirmation]: {};
}

export interface OtbMachineStates extends Typestate<OtbMachineContext> {
  type: OtbMachineStatesSchema;
}

// Event Types
export enum OtbEvents {
  OTBType = 'OTBType',
  IncorrectSSN = 'IncorrectSSN',
  Next = 'Next',
  NEXT = 'NEXT',
  BACK = 'BACK',

  NotRecognized = 'NotRecognized',
  ApplicationStatus = 'ApplicationStatus',
  CustomerIdentificationButton = 'CustomerIdentificationButton',
  TermsAndConditionsButton = 'TermsAndConditionsButton',
  AuthorizedUserTermsButton = 'AuthorizedUserTermsButton',
}

type OtbEventsTypeSchema =
  | OtbEvents.OTBType
  | OtbEvents.IncorrectSSN
  | OtbEvents.NEXT
  | OtbEvents.BACK
  | OtbEvents.NotRecognized
  | OtbEvents.ApplicationStatus
  | OtbEvents.CustomerIdentificationButton
  | OtbEvents.TermsAndConditionsButton
  | OtbEvents.AuthorizedUserTermsButton;

export interface OtbMachineEvents extends EventObject {
  type: OtbEventsTypeSchema;
  data?: any;
}

// Guard Types
export enum OtbGuardsType {
  EXISTING_CUSTOMER_FOUND = 'ExistingCustomerFound',
  CUSTOMER_OTB = 'CustomerOTB',
  CUSTOMER_DECLINED = 'CustomerDeclined',
  CUSTOMER_NOT_FOUND = 'CustomerNotFound',
  SKIP_TO_PAGE = 'SkipToPage',
  NORMAL_INITIAL = 'NormalInitial',
}
type OtbGuardsTypeSchema =
  | OtbGuardsType.EXISTING_CUSTOMER_FOUND
  | OtbGuardsType.CUSTOMER_OTB
  | OtbGuardsType.CUSTOMER_NOT_FOUND
  | OtbGuardsType.CUSTOMER_DECLINED
  | OtbGuardsType.NORMAL_INITIAL
  | OtbGuardsType.SKIP_TO_PAGE;

export interface OtbMachineGuards extends EventObject {
  type: OtbGuardsTypeSchema;
}

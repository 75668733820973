import { useStoreState } from '../../hooks/easyPeasyHooks';
import React from 'react';
import { useTrack } from '../../hooks/segment';
import CartError from '../cartError/CartError';
import LeaseTermsTooltip from '../lease-terms-tooltip/lease-terms-tooltip';
import LoanTermsTooltip from '../loans-terms-tooltip/loans-terms-tooltip';
import { convertToCurrency } from '../../helpers/converters/converters';
import { AppMode } from '../../stores/order.store';
import { launchPageTypes } from '../../stores/auth.store';
import { NON_LEASABLE_PERCENTAGE, NON_LEASABLE_MULTIPLIER } from '../../constants/cart-validation';
import { ChannelTypes } from '../../gql/graphql';

interface IProps {
  approvedAmount: any;
  minimumSpend?: number | null;
  children?: any;
}
const CartValidation: React.FC<IProps> = (props: IProps) => {
  const { totalCartAmount, nonLeasableItems, appMode, order } = useStoreState((state) => state.order);
  const storeName = useStoreState((state) => state.order?.order?.dealer?.storeName);
  const { isCurrentApplyTypeOTB } = useStoreState((state) => state.applicationForm);
  const isOTB = isCurrentApplyTypeOTB || !!order?.application?.lease?.isOtb || !!order?.application?.loan?.isOtb;
  const isVirtualCard = order?.dealer?.isVirtualCard;
  const isBrickAndMortar = order?.dealer?.channelType === ChannelTypes.BrickAndMortar;
  const { launchPage } = useStoreState((state) => state.auth);
  const minimumSpend = !!props.minimumSpend ? props.minimumSpend : 200;
  const track = useTrack();

  const eligibiltyText = isOTB ? 'approved' : 'prequalified';

  let nonLeaseableItemsTotal = 0;

  const getFinancingTooltips = () => {
    let tooltip;

    if (appMode === AppMode.loan || !!order?.application?.loan) {
      tooltip = <>a {<LoanTermsTooltip styling={{ fontSize: '1.75rem' }} />}</>;
    } else if (appMode === AppMode.lease || !!order?.application?.lease) {
      tooltip = <>{<LeaseTermsTooltip styling={{ fontSize: '1.75rem' }} />} financing</>;
    }

    return (
      <div>
        You have been {eligibiltyText} for {tooltip}
        {' up to '}
        {convertToCurrency(props.approvedAmount, 0)}
      </div>
    );
  };

  nonLeasableItems.forEach((item) => {
    nonLeaseableItemsTotal = nonLeaseableItemsTotal + Number(item.price) * Number(item.quantity);
  });
  const nonLeaseableBody = `You must adjust your cart to be able to checkout with Koalafi, the following items must make up at most ${NON_LEASABLE_PERCENTAGE} (${convertToCurrency(
    totalCartAmount * NON_LEASABLE_MULTIPLIER,
  )}) of your cart total:`;

  if (isVirtualCard || isBrickAndMortar) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  if (launchPage !== launchPageTypes.checkout) {
    track('Cart Error', { error: 'trying to continue with checkout outside of checkout of VC flow' });
    return (
      <CartError
        heading={getFinancingTooltips()}
        body={`Finish shopping ${
          storeName ? `at ${storeName}` : 'with this merchant'
        } and select 'Pay with Koalafi' at checkout to complete your purchase.`}
        cartTotal={totalCartAmount}
        approvalAmount={props.approvedAmount}
        errorCode={5}
        isOTB={isOTB}
      />
    );
  }

  if (totalCartAmount === 0) {
    track('Cart Error', { error: 'emptyCart' });
    return (
      <CartError
        heading={getFinancingTooltips()}
        body="Keep shopping and select Koalafi as your payment method at checkout."
        cartTotal={totalCartAmount}
        approvalAmount={props.approvedAmount}
        errorCode={5}
        isOTB={isOTB}
      />
    );
  } else if (totalCartAmount < minimumSpend) {
    track('Cart Error', { error: "cart doesn't meet min" });
    return (
      <CartError
        heading="Oops!"
        body={`Your cart is below the minimum amount of ${convertToCurrency(
          minimumSpend,
        )}. Please add items to your cart to continue.`}
        cartTotal={totalCartAmount}
        approvalAmount={props.approvedAmount}
        errorCode={4}
        isOTB={isOTB}
      />
    );
  } else if (totalCartAmount > parseInt(props.approvedAmount)) {
    track('Cart Error', { error: 'cart exceeds max' });
    return (
      <CartError
        heading="Oops!"
        body={`You've exceeded your prequalification amount. Please remove an item from your cart to proceed`}
        // body="You've exceeded your approval amount. Please remove an item from your cart to proceed"
        cartTotal={totalCartAmount}
        approvalAmount={props.approvedAmount}
        errorCode={6}
        isOTB={isOTB}
      />
    );
  } else if (appMode === AppMode.lease && nonLeaseableItemsTotal / totalCartAmount > NON_LEASABLE_MULTIPLIER) {
    track('Cart Error', { error: 'too many nonLeaseable Items' });
    return (
      <CartError
        heading="Oops!"
        body={nonLeaseableBody}
        nonLeaseableItems={nonLeasableItems}
        cartTotal={totalCartAmount}
        approvalAmount={props.approvedAmount}
        errorCode={8}
        isOTB={isOTB}
      />
    );
  } else {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
};

export default CartValidation;

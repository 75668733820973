import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useStoreState } from '../../hooks/easyPeasyHooks';
import { AppMode } from '../../stores/order.store';
import { useTrack, useIdentify, usePage } from '../../hooks/segment';
import { useSessionProperies, useTagUser } from '../../hooks/dtrum';
import { getLowestMinimumSpend as getLeaseLowestMinimumSpend } from '../../helpers/leases/leases.helpers';
import { ReactComponent as WallySunglassesImage } from '../../assets/Wally_Sunglasses.svg';
import { convertToCurrency } from '../../helpers/converters/converters';
import ApprovalAmount from '../../components/approvalAmount/ApprovalAmount';
import { FormNavButtonWrapper } from '../../components/formElements/FormElements';
import Button from '../../components/button/Button';
import CartValidationErrContent from '../../components/approvalContent/CartValidationErrContent';
import { ExitCodes, ModalMessage, ModalMessageFailure, modalResolver } from '../../helpers/modal/modal.helper';
import { greatestLineAmountHelper } from '../../helpers';
import { getLowestMinimumSpend as getLoanLowestMinimumSpend } from '../../helpers/loans/loans.helpers';
import { ChannelTypes, IntegrationEntryType } from '../../gql/graphql';
import WallyApproval from '../../components/wally_approval';
import TermsAndConditions from './termsAndConditions';

type Props = {
  prom?: ((...args: any[]) => Promise<void>) | undefined;
  onNext: any;
};
type StyleProps = {
  isHybrid: boolean;
};

const Gradient = styled.div<StyleProps>`
  width: 100%;
  max-width: 465px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 1.5rem auto 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0) 100%);
  height: 50px;
`;

const WallyImgStyle = styled.div`
  position: relative;
  width: 5rem;
  right: 1.5rem;
  bottom: -2.4rem;
  z-index: 1; // without this, the gradient overlaps wally's feet
`;

const WelcomeBack: React.FC<Props | undefined> = ({ prom, onNext }: any) => {
  const { order } = useStoreState((state) => state.order);
  //TODO:: default to 12 month lease option as we dont let the customer choose this yet but we will in the term selection page not going into v1 of shortform
  const { totalCartAmount, orderId, hasItems } = useStoreState((state) => state.order);
  const appMode = useStoreState((state) => state.order.appMode);
  const dealerId = useStoreState((state) => state.order.order?.dealer.id);
  const application = useStoreState((state) => state.order.order?.application);
  const domains = useStoreState((state) => state.order.order?.dealer.domains!);
  const { isCurrentApplyTypeOTB } = useStoreState((state) => state.applicationForm);
  const isOTB = isCurrentApplyTypeOTB || !!order?.application?.lease?.isOtb || !!order?.application?.loan?.isOtb;
  const track = useTrack();
  const identify = useIdentify();
  const page = usePage();
  const dtrumProps = useSessionProperies();
  const dtrumUser = useTagUser();
  const isHybrid =
    order?.dealer.integrationDetails?.integrationType === IntegrationEntryType.Hybrid ||
    order?.dealer.integrationDetails?.integrationType === IntegrationEntryType.HybridPlugin;
  const isBrickAndMortar = order?.dealer?.channelType === ChannelTypes.BrickAndMortar;

  const cartValidationErr = application?.lease?.cartValidationDetails || application?.loan?.cartValidationDetails;

  type PageValueDetails = {
    approvalAmount: string | undefined | null;
    nextPageRoute: string;
  };

  type PageValues = {
    [AppMode.lease]: PageValueDetails;
    [AppMode.loan]: PageValueDetails;
  };

  const [checked, setChecked] = useState(false);

  // stores the page values based on appMode
  const pageValues: PageValues = {
    [AppMode.lease]: {
      approvalAmount: order?.application?.lease?.maxLeaseAmount,
      nextPageRoute: '/flow/pay-frequency',
    },
    [AppMode.loan]: {
      // @ts-ignore
      approvalAmount:
        greatestLineAmountHelper(order?.application?.loan?.options, totalCartAmount) ??
        order?.application?.loan?.maxLoanAmount,
      nextPageRoute: '/flow/payment-schedule',
    },
  };

  useEffect(() => {
    identify({
      customerId: application?.customer?.id!,
      emailAddress: application?.customer?.emailAddress,
      cellPhone: application?.customer?.cellPhone,
      firstName: application?.customer?.firstName,
      lastName: application?.customer?.lastName,
    });

    // set Dynatrace session properties
    dtrumProps({
      firstname: application?.customer?.firstName as string,
      lastname: application?.customer?.lastName as string,
      emailaddress: application?.customer?.emailAddress as string,
      appmode: appMode as string,
      orderid: orderId as string,
    });

    // set dynatrace user tag as email address
    dtrumUser(application?.customer?.emailAddress as string);

    track('Existing Customer Confirmed');

    page('Welcome Back', { orderId, dealerId, totalItemsCost: totalCartAmount });
    track('Cart Entered', { totalItemsCost: totalCartAmount });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getButtonText = () => {
    if (cartValidationErr) {
      return 'Back to cart';
    } else if (order?.dealer?.isVirtualCard || isBrickAndMortar) {
      return 'Continue';
    } else if (hasItems) {
      return 'Next: Check out with Koalafi';
    } else {
      return 'Next: Continue shopping';
    }
  };

  const getHeaderText = () => {
    if (isHybrid || isBrickAndMortar) {
      return (
        <div style={{ display: 'flex', position: 'relative' }}>
          <WallyApproval
            status={order?.application?.lease?.status || order?.application?.loan?.status}
            isOTB={isOTB}
            appMode={appMode}
            financingAmount={
              appMode === AppMode.loan
                ? order!.application!.loan!.maxLoanAmount!
                : order!.application!.lease!.maxLeaseAmount!
            }
            isVirtualCard={!!order?.dealer?.isVirtualCard}
          />
        </div>
      );
    }
    return (
      <>
        <h1 style={{ fontSize: '3.5rem' }}>
          Welcome back{application?.customer?.firstName && `, ${application?.customer?.firstName}`}
        </h1>
        <div style={{ display: 'flex', position: 'relative' }}>
          <ApprovalAmount amount={pageValues[appMode!].approvalAmount} isOTB={isOTB} />
          <WallyImgStyle>
            <WallySunglassesImage />
          </WallyImgStyle>
        </div>
      </>
    );
  };

  const handleSubmit = () => {
    if (!!cartValidationErr) {
      const payload: ModalMessage = {
        wcfSuccess: false,
        closeModal: true,
        payload: {
          reason: 'There was a cart Validation Error',
          reasonCode: ExitCodes.genericCartValidationErr,
          orderId: orderId,
        } as ModalMessageFailure,
      };
      modalResolver(payload, domains);
    } else if (!hasItems && !isBrickAndMortar) {
      const payload: ModalMessage = {
        wcfSuccess: false,
        closeModal: true,
        payload: {
          reason: 'modal closed',
          reasonCode: ExitCodes.generalCloseModal,
          orderId: orderId,
        } as ModalMessageFailure,
      };
      modalResolver(payload, domains);
    } else {
      onNext();
    }
  };

  const isAgreementVisibleAndNotChecked = isHybrid && !cartValidationErr && hasItems && !checked;

  return (
    <div>
      {getHeaderText()}
      <Gradient isHybrid={isHybrid} />
      <div style={{ marginTop: '3rem' }}>
        {!!cartValidationErr ? (
          <CartValidationErrContent
            pageAppMode={appMode}
            cartValidationErr={cartValidationErr}
            approvalAmount={
              appMode === AppMode.loan
                ? order!.application!.loan!.maxLoanAmount!
                : order!.application!.lease!.maxLeaseAmount!
            }
            minimumSpend={
              appMode === AppMode.loan
                ? getLoanLowestMinimumSpend(order?.application?.loan?.options)
                : getLeaseLowestMinimumSpend(order?.application?.lease?.options)
            }
          />
        ) : hasItems ? (
          <>
            <h6>
              Your current cart total is <strong>{convertToCurrency(totalCartAmount)}</strong>. Continue to checkout
              with the button below or close this window to continue shopping.
            </h6>
            {!!isHybrid && <TermsAndConditions setChecked={setChecked} checked={checked} />}
          </>
        ) : isBrickAndMortar ? (
          <h6>You're almost done, we need a little more information to complete your application.</h6>
        ) : (
          <h6>
            Keep shopping at {order?.dealer.storeName} and <strong>select Koalafi as your payment method</strong> at
            checkout. You'll be able to review all your {appMode === AppMode.loan ? 'loan' : 'lease'} terms before you
            complete your purchase.
          </h6>
        )}

        <div>
          <FormNavButtonWrapper>
            <Button disabled={isAgreementVisibleAndNotChecked} onClick={handleSubmit}>
              {getButtonText()}
            </Button>
          </FormNavButtonWrapper>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBack;

import styled from '@emotion/styled';
import React from 'react';

import { useStoreState } from '../../hooks/easyPeasyHooks';
import { usePage, useTrack } from '../../hooks/segment';
import { useReportError } from '../../hooks/dtrum';
import InternalError from '../../pages/internalError/InternalError';
import { fontFamilies } from '../baseStyle/Variables';
import Header from '../header/Header';

const ErrorWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: ${fontFamilies.gilroyRegular};
  justify-content: center;
  margin: 0 auto;
  padding: 0 2rem 2rem;
  width: 75%;
`;

type Props = {
  error?: any;
  body?: string;
  additionalBody?: string;
  buttonText?: string;
};

const CustomErrorBoundary: React.FC<Props> = (props: Props) => {
  const { error, body, additionalBody, buttonText } = props;
  const reportErr = useReportError();

  if (error) {
    reportErr(error);
    console.error(error);
    console.error(error?.stack);
    console.trace();
  }

  const { order } = useStoreState((state) => state.order);
  const storeName = useStoreState((state) => state.order?.order?.dealer?.storeName);
  const track = useTrack();
  const page = usePage();

  page('ErrorBoundary', props.error);
  track('ErrorBoundary - somthing went wrong', { ...order, error });
  return (
    <>
      <Header goBackFunc={undefined} currentNode={['CustomErrorBoundary']} />
      <ErrorWrapper id="ErrorBoundary">
        <InternalError
          heading=" "
          body={
            body
              ? body
              : `We're sorry, there's been an unexpected error - please try again. If the problem continues, please contact ${storeName} for assistance.`
          }
          additionalBody={additionalBody ? additionalBody : ''}
          buttonText={buttonText ? buttonText : ''}
        />
      </ErrorWrapper>
    </>
  );
};

export default CustomErrorBoundary;

import React from 'react';
import styled from '@emotion/styled';
import { colors, fontFamilies } from '../baseStyle/Variables';

interface ProgressSectionProps {
  progressText: string;
  isHighlighted: { eligibility: boolean; details: boolean; review: boolean };
}

type StylingProps = {
  isSelected?: boolean;
  color?: string;
  filled?: boolean;
};

const SectionContainerStyle = styled.div`
  width: calc(100% / 3);
`;

const ProgressSectionStyle = styled.div<StylingProps>`
  font-family: ${(props: any) => (props.isSelected ? fontFamilies.interRegular : fontFamilies.interLight)};
  color: ${(props: any) => (props.isSelected ? colors.koalafiBlue : colors.gray)};
  font-size: 0.8rem;
  text-align: center;
  @media (max-width: 370px) {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Circle = styled.div<StylingProps>`
  background: ${(props: any) => (props.filled ? colors.koalafiBlue : colors.white)};
  height: 10px;
  width: 10px;
  border: 1px ${colors.koalafiBlue} solid;
  border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
  color: ${colors.white};
  font-size: 9px;
  text-align: center;
  font-family: ${fontFamilies.interRegular};
`;

const FullBar = styled.div`
  background: ${colors.koalafiTurquoise};
  height: 1px;
  width: 100%;
`;

const HalfBar = styled.div<StylingProps>`
  background: ${(props: StylingProps) => props.color};
  height: 1px;
  width: calc((100% - 10px) / 2);
`;

const ColorStyle = styled.div`
  display: flex;
  align-items: center;
  height: 12px;
`;

const SecondProgress: React.FC<ProgressSectionProps> = ({ isHighlighted, progressText }) => {
  const gradientColor = `linear-gradient(90deg, ${colors.koalafiBlue}  35%, ${colors.koalafiTurquoise}  100%)`;
  const blueColor = colors.koalafiBlue;
  const checked = isHighlighted.review;
  return (
    <SectionContainerStyle>
      <ColorStyle>
        {!!isHighlighted.eligibility && <FullBar />}
        {(!!isHighlighted.details || isHighlighted.review) && (
          <>
            <HalfBar color={blueColor} />
            <Circle filled={checked}>{checked ? '✓' : ''}</Circle>
            <HalfBar color={isHighlighted.details ? gradientColor : blueColor} />
          </>
        )}
      </ColorStyle>
      <ProgressSectionStyle isSelected={isHighlighted.details}>{progressText}</ProgressSectionStyle>
    </SectionContainerStyle>
  );
};

export default SecondProgress;

import { LeaseOptions } from '../../gql/graphql';

/**
 * greatestApprovalAmountHelper gets the greatest approval amount from all lease options
 */
const greatestApprovalAmountHelper = (leaseOptions: LeaseOptions[] | null | undefined, cartTotal: number) => {
  if (!leaseOptions || leaseOptions.length === 0) {
    return null;
  }

  if (leaseOptions.length === 1) {
    return leaseOptions[0].approvedAmount;
  }

  var leaseOptionsWithinMinimumSpend = leaseOptions.filter(
    (leaseOption) => cartTotal === 0 || Number(leaseOption.minimumSpend) <= cartTotal,
  );

  if (leaseOptionsWithinMinimumSpend.length === 0) {
    return null;
  }

  return Math.max(
    ...leaseOptionsWithinMinimumSpend.map((leaseOption) => Number(leaseOption.approvedAmount)),
  ).toString();
};

/**
 * selectLeaseHelper gets the lease with the greatest approval amount
 */
const selectLeaseHelper = (leaseOptions: LeaseOptions[] | null | undefined) => {
  if (!leaseOptions || leaseOptions.length === 0) return null;

  return leaseOptions.reduce((prevVal, curVal) =>
    Number(curVal?.approvedAmount) > Number(prevVal?.approvedAmount) ? curVal : prevVal,
  );
};

/**
 * selectLeaseHelper gets the lease with the lowest minimum spend
 */
const getLowestMinimumSpend = (leaseOptions: LeaseOptions[] | null | undefined) => {
  if (!leaseOptions || leaseOptions.length === 0) return null;

  return leaseOptions.length === 1
    ? Number(leaseOptions[0].minimumSpend)
    : Math.min(...leaseOptions.map((leaseOption) => Number(leaseOption.minimumSpend)));
};

export { greatestApprovalAmountHelper, selectLeaseHelper, getLowestMinimumSpend };
